import React, { useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel3';



import { Link } from "react-router-dom";

import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ImgOfferBanner from '../assets/img/offer-banner.png';
import ImgMOfferBanner from '../assets/img/m-offer-banner.png';
import ImgBrand1 from '../assets/img/brand-1.png';

import ImgSale1 from '../assets/img/sale-1.png';
import ImgSale2 from '../assets/img/sale-2.png';
import ImgSale3 from '../assets/img/sale-3.png';
import ImgSale4 from '../assets/img/sale-4.png';

import ImgComboPack from '../assets/img/combo-pack.png';
import ImgDiscount1 from '../assets/img/discount-1.png';
import ImgDiscount2 from '../assets/img/discount-2.png';
import ImgDiscount3 from '../assets/img/discount-3.png';

import apiPath from "../constants/apiPath";
import Http from "../services/Http";

import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Banner from './Component/Banner';
import { off } from 'rsuite/esm/DOMHelper';



import LocalizedStrings from 'react-localization';
import localizations from "../localization";
let strings = new LocalizedStrings(localizations);

const regex = /(<([^>]+)>)/ig;

const options = {
  margin: 0,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
      margin: 7.5
    },
    420: {
      items: 1
    },
    577: {
      items: 1
    },
    992: {
      items: 1
    }
  }
};

const brandOption = {
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1.5,
      margin: 7.5
    },
    577: {
      items: 3
    },
    992: {
      items: 4
    },
    1366: {
      items: 5
    }
  }
}

const flowersOption = {
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 2.3,
      margin: 7.5
    },
    577: {
      items: 3
    },
    992: {
      items: 5
    },
    1366: {
      items: 6
    }
  }
}

const comboOption = {
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
      margin: 7.5
    },
    420: {
      items: 2
    },
    577: {
      items: 2
    },
    992: {
      items: 2
    },
    1366: {
      items: 3
    }
  }
}

const DiscountOption = {
  margin: 15,
  nav: true,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
      margin: 7.5
    },
    420: {
      items: 2
    },
    577: {
      items: 2
    },
    992: {
      items: 3
    },
    1366: {
      items: 4
    }
  }
}





const Offer = (Props) => {
  const [offer, setOffer] = useState([]);


  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("current_lang")
      ? localStorage.getItem("current_lang")
      : "en"
  );
  strings.setLanguage(currentLang);


  const getOffer = async () => {
    let path = apiPath.getOffer + '?type=web';
    var data = new FormData();
    let options = {
      method: 'GET',
    }
    let result = await Http(path, options);
    if (result.status == 200) {
      if (result.data.status) {
        setOffer({ data: result.data.data })
      }
      else {
        toast.error(result.data.message);
      }
    }
  }


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getOffer();
  }, []);


  return (
    <>
      <Header />
      <main>
        {offer.data && offer.data.offer_banner ?
          <section className="banner-sec">
            <Banner data={offer.data.offer_banner} options={options} type="Banner" />
          </section> : null}
        {offer.data && offer.data.sub_category_discount ?
          <section className="space-cls brand-sec">
            <div className="container">
              <div className="brand-listing">

                {offer.data && offer.data.sub_category_discount ?


                  <OwlCarousel className="owl-carousel  owl-theme" {...brandOption}  >

                    {offer.data.sub_category_discount.map((value) =>
                      <div className="item">
                        <a href={"/product/sub/" + value.offer_type_id}>
                          <div className="brand-cls">
                            <div className="brand-img">
                              <img src={value.image} alt="Brand" />
                            </div>
                            <div className="brand-cont">
                              <p className="brand-tag">{value.code}</p>
                              <p dangerouslySetInnerHTML={{ __html: value.description }}></p>
                            </div>
                          </div>
                        </a>
                      </div>
                    )}

                    {/* <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div> */}

                  </OwlCarousel>
                  : <Skeleton height={50} count={5} />}
              </div>
            </div>
          </section> : null}
        {offer.data && offer.data.brand_discount ?
          <section className="space-cls sale-sec">
            <div className="container">
              {offer.data && offer.data.brand_discount ?
                <OwlCarousel className="owl-carousel  owl-theme" {...flowersOption}  >
                  {offer.data.brand_discount.map((value) =>
                    <div className="item">
                      <div className="sale-prd">

                        <Link to={'/product/brand/' + value.brand_id}>
                          <img src={value.image} alt="" />
                        </Link>
                      </div>
                    </div>
                  )}


                </OwlCarousel> : <Skeleton height={50} count={5} />}

            </div>
          </section> : null}
        <section className="space-cls brand-sec">
          <div className="container">
            <div className="brand-listing">

              {offer.data && offer.data.category ?


                <OwlCarousel className="owl-carousel  owl-theme" {...brandOption}  >

                  {offer.data.category.map((value) =>
                    <div className="item">
                      <a href={"/product/category/" + value.offer_type_id}>
                        <div className="brand-cls">
                          <div className="brand-img">
                            <img src={value.image} alt="Brand" />
                          </div>
                          <div className="brand-cont">
                            <p className="brand-tag">{value.code}</p>
                            <p dangerouslySetInnerHTML={{ __html: value.description }}></p>
                          </div>
                        </div>
                      </a>
                    </div>
                  )}

                  {/* <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="brand-cls">
                      <div className="brand-img">
                        <img src={ImgBrand1} alt="Brand"/>
                      </div>
                      <div className="brand-cont">
                        <p className="brand-tag">Buy 1 Get 1 Free</p>
                        <p>On The Entire Page</p>
                      </div>
                    </div>
                  </div> */}

                </OwlCarousel>
                : <Skeleton height={50} count={5} />}
            </div>
          </div>
        </section>
        <section className="space-cls combo-sec">
          <div className="container">


            {offer.data && offer.data.combo ?
              <OwlCarousel className="owl-carousel  owl-theme" {...comboOption}  >

                {offer.data.combo.map((item, index) =>

                  <div className="item">
                    <div className="combo-img">
                      <Link to={"/product-combo-detail/" + item.id}>
                        <img src={item.image} alt="Combo" />
                      </Link>
                    </div>
                  </div>
                )}
              </OwlCarousel>
              : <Skeleton height={50} count={5} />}

          </div>
        </section>

        <section className="space-cls discount-sec">
          <div className="container">
            <div className="discount-listing">
              {offer.data ?
                <OwlCarousel className="owl-carousel  owl-theme" {...DiscountOption}  >

                  {offer.data.other_discount.map((item, index) =>

                    <div className="item">
                      <div className="brand-cls">
                        <div className="brand-img">
                          <img src={item.image} alt={item.name} />
                        </div>
                        <div className="brand-cont">
                          <p className="brand-tag" > <span dangerouslySetInnerHTML={{ __html: item.description }} />  <b>({strings.Promocode} : {item.code})</b></p>

                        </div>
                      </div>
                    </div>
                  )}


                </OwlCarousel> : <Skeleton height={50} count={5} />}
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  )
}

export default Offer;